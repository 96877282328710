import {
  Article,
  Blog,
  BreadcrumbList,
  Organization,
  Person,
  SocialProfile,
  Website,
} from './types'

const brandName = 'jsmanifest'
const logoUrl =
  'https://jsmanifest.s3-us-west-1.amazonaws.com/logo/black_on_transparent192x52.png'

export function getArticleSeo({
  alternativeHeadline,
  articleBody,
  author,
  datePublished,
  dateCreated,
  dateModified,
  description,
  editor,
  genre,
  headline,
  image,
  keywords,
  url,
  wordcount,
  ...rest
}: Article) {
  return {
    '@context': 'https://schema.org',
    '@type': 'Article',
    alternativeHeadline,
    articleBody,
    author,
    datePublished,
    dateCreated,
    dateModified,
    description,
    editor,
    genre,
    headline,
    image,
    keywords,
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    publisher: {
      '@type': 'Organization',
      name: brandName,
      logo: {
        '@type': 'ImageObject',
        url: logoUrl,
      },
    },
    url,
    wordcount,
    ...rest,
  }
}

export function getBlogSeo({
  alternativeHeadline,
  author,
  articleBody,
  copyrightYear,
  copyrightHolder,
  datePublished,
  dateCreated,
  dateModified,
  description,
  editor,
  genre,
  headline,
  image,
  isFamilyFriendly = true,
  keywords = [],
  mainEntityOfPage = 'True',
  url,
  wordcount,
}: Blog) {
  return {
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    alternativeHeadline,
    articleBody,
    author: {
      '@type': 'Person',
      name: author,
      url,
    },
    creator: {
      '@type': 'Person',
      name: author,
      url,
    },
    copyrightYear,
    copyrightHolder,
    datePublished,
    dateCreated,
    dateModified,
    description,
    editor,
    genre,
    headline,
    image,
    isFamilyFriendly,
    keywords,
    mainEntityOfPage,
    publisher: {
      '@type': 'Organization',
      name: brandName,
      url,
      logo: {
        '@type': 'Organization',
        url,
        width: 400,
        height: 55,
      },
    },
    url,
    wordcount,
  }
}

export function getBreadCrumbListSeo({ sites, thumbnail }: BreadcrumbList) {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: sites.map(({ url, name }, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      item: {
        '@type': 'Thing',
        '@id': url,
        name,
        image: thumbnail,
      },
    })),
  }
}

export function getOrganizationSeo({
  siteUrl,
  siteName,
  siteLogo,
  foundingDate,
  founders = [],
  sameAs,
}: Organization) {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: siteName,
    url: siteUrl,
    logo: siteLogo,
    foundingDate,
    founders: founders.map((name: string) => ({
      '@type': 'Person',
      name,
    })),
    sameAs,
  }
}

export function getPersonSeo({ name, url, sameAs }: Person) {
  return {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name,
    url,
    sameAs,
    gender: 'male',
    jobTitle: 'Owner',
    address: {
      '@type': 'PostalAddress',
      addressRegion: 'California',
      addressCountry: 'United States',
    },
    birthPlace: 'California',
    nationality: 'Asian American',
  }
}

export function getSocialProfileSeo({ name, url, socialUrls }: SocialProfile) {
  return {
    '@context': 'https://schema.org',
    '@type': 'Person',
    name,
    url,
    sameAs: socialUrls,
  }
}

export function getWebsiteSeo({ siteUrl, siteName }: Website) {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    url: siteUrl,
    name: siteName,
  }
}
