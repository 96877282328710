import React from 'react'

export interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  display?: React.CSSProperties['display']
  alignItems?: React.CSSProperties['alignItems']
  justify?: React.CSSProperties['justifyContent']
  justifyContent?: React.CSSProperties['justifyContent']
  direction?: React.CSSProperties['flexDirection']
  flexDirection?: React.CSSProperties['flexDirection']
}

function Flex({
  children,
  style,
  display = 'flex',
  alignItems = 'center',
  justify,
  justifyContent,
  direction,
  flexDirection,
  ...rest
}: React.PropsWithChildren<FlexProps>) {
  return (
    <div
      style={{
        ...style,
        display,
        alignItems,
        justifyContent: justify || justifyContent,
        flexDirection: direction || flexDirection,
      }}
      {...rest}
    >
      {children}
    </div>
  )
}

export default Flex
