import React from 'react'
import { css } from '@emotion/react'
import Typography, { TypographyProps } from '@mui/material/Typography'

export interface MarkdownProps extends TypographyProps {
  content?: string
}

const fontStylesCss = css`
  font-family: Consolas, Menlo, Monaco, source-code-pro, Courier New, monospace;
  font-size: 10.5px;
`

function Markdown(props: React.PropsWithChildren<MarkdownProps>) {
  const { content = '', ref, ...rest } = props

  return (
    <Typography
      {...rest}
      component="div"
      dangerouslySetInnerHTML={{ __html: props.children ? '' : content }}
      ref={ref as React.RefObject<HTMLDivElement>}
      css={css`
        .language-js,
        .language-jsx,
        .language-ts,
        .language-tsx,
        .language-css,
        .language-shell,
        .language-bash,
        .language-json,
        .language-text,
        .token {
          ${fontStylesCss}
        }
        .token.attr-name {
          color: #c792ea;
        }
        .token.attr-value {
          color: #fff;
        }
        .token.class-name {
          color: #ffcb6b;
        }
        .token.function {
          color: #82aaff;
        }
        .token.function-variable.function {
          color: #89ddff;
        }
        .token.keyword {
          color: #c792ea;
        }
        .token.parameter {
          color: #eee;
        }
        .token.plain-text {
        }
        .token.punctuation {
          color: #89ddff;
        }
        .token.string {
          color: #c3e88d;
        }
        .token,
        code,
        pre {
          font-family: Helvetica;
          font-weight: 400;
          font-size: 11px;
        }
        code.language-text {
          background: #fbf1ff;
          color: #333;
          font-family: Roboto;
          font-size: 13px;
          font-weight: 400;
          padding-left: 4px;
          padding-right: 4px;
        }
        pre[class*='language-'] {
          background: #35464b;
          border-radius: 8px;
        }
        :not(pre) > code[class*='language-'] {
          background: #35464b;
          border-radius: 8px;
          color: #333;
          padding-left: 3px;
          padding-right: 3px;
        }
        p {
          font-family: Roboto;
          font-weight: 300;
          font-size: 14px;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: Roboto;
          font-weight: 400;
        }
        img {
          width: 100%;
        }
      `}
    />
  )
}

export default Markdown
