import React from 'react'
import { css } from '@emotion/react'
import kebabCase from 'lodash/kebabCase'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import { graphql, Link } from 'gatsby'
import { DiscussionEmbed } from 'disqus-react'
import { baseUrl } from 'app/config'
import { ArticleMeta } from 'components/SEO'
import Flex from 'components/common/Flex'
import FollowBar from 'components/FollowBar'
import Page from 'components/Page'
import Markdown from 'components/Markdown'

interface PostTemplateProps {
  data: {
    markdownRemark: {
      html: string
      excerpt: string
      wordCount: {
        words: number
      }
      fields: {
        slug: string
        date: string
      }
      frontmatter: {
        title: string
        date: string
        description: string
        lastUpdated: string
        tags: string[]
        author: string
        thumbnail: string
        category: string
      }
    }
    html: string
  }
  path: string
}

function PostTemplate({ data, path }: PostTemplateProps) {
  const post = data.markdownRemark

  // console.log('PostTemplate', arguments[0])

  const disqusConfig = {
    shortname: 'jsmanifest',
    config: {
      url: `${baseUrl}${path}`,
      title: post.frontmatter.title,
      identifier: post.fields.slug,
    },
  }

  const divider = <hr style={{ margin: '50px 0', opacity: 0.5 }} />

  return (
    <>
      <ArticleMeta
        articleBody={post.excerpt}
        author={post.frontmatter.author}
        date={post.frontmatter.date}
        datePublished={post.frontmatter.date}
        dateModified={post.frontmatter.lastUpdated}
        description={post.frontmatter.description}
        excerpt={post.excerpt}
        genre={post.frontmatter.category}
        path={post.fields.slug}
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        thumbnail={post.frontmatter.thumbnail}
        wordCount={post.wordCount.words}
      />
      <Page id="post" variant="post">
        <Typography variant="h5" align="center" gutterBottom paragraph>
          {post.frontmatter.title}
        </Typography>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            > div {
              width: 50px;
            }
          `}
        >
          <Typography className="author">{post.frontmatter.author}</Typography>
          <div />
          <Typography className="date">{post.fields.date}</Typography>
        </div>
        <img
          css={css`
            margin: 20px auto 35px;
            width: 100%;
          `}
          src={post.frontmatter.thumbnail}
        />
        <FollowBar
          baseUrl={baseUrl}
          slug={post.fields.slug}
          title={post.frontmatter.title}
        />
        <Markdown content={post.html} />
        {!!post.frontmatter.tags.length && divider}
        <Typography variant="h4" align="center" gutterBottom>
          Tags
        </Typography>
        <Flex justify="center">
          {post.frontmatter.tags.map((tag) => (
            <Link key={tag} to={`/tags/${kebabCase(tag)}/`}>
              <Chip style={{ margin: 2 }} label={tag} clickable />
            </Link>
          ))}
        </Flex>
        {divider}
        {process.env.NODE_ENV === 'production' && (
          <DiscussionEmbed {...disqusConfig} />
        )}
      </Page>
    </>
  )
}

export const query = graphql`
  query ($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      html
      excerpt(pruneLength: 160)
      timeToRead
      wordCount {
        words
      }
      fields {
        slug
        date(formatString: "MMMM Do, YYYY")
      }
      frontmatter {
        title
        date
        thumbnail
        author
        lastUpdated
        description
        tags
        category
      }
    }
  }
`

export default PostTemplate
