import React from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import {
  getArticleSeo,
  getBreadCrumbListSeo,
  getOrganizationSeo,
  getSocialProfileSeo,
  getWebsiteSeo,
} from 'components/SEO/utils'
import { baseUrl, socialProfiles } from 'app/config'
import { Script } from './types'

const siteQuery = graphql`
  query SEO {
    site {
      siteMetadata {
        siteUrl
        siteName
        siteTitle
        siteDescription
        siteLogo
      }
    }
  }
`

export function DefaultMeta({
  keywords = [],
  breadCrumb = [],
  script = [],
}: {
  keywords?: string[]
  breadCrumb?: Array<{ url?: string; name?: string }>
  script?: any[]
}) {
  return (
    <StaticQuery
      query={siteQuery}
      render={({
        site: {
          siteMetadata: {
            siteName = '',
            siteUrl = '',
            siteTitle = '',
            siteDescription = '',
            siteLogo = '',
          },
        },
      }) => {
        const structuredData = [
          getWebsiteSeo({
            siteUrl,
            siteName,
          }),
          getOrganizationSeo({
            siteUrl,
            siteName,
            siteLogo,
            founders: ['Christopher Tran'],
            foundingDate: '05/20/2019',
            sameAs: [
              socialProfiles.devto,
              socialProfiles.disqus,
              socialProfiles.linkedIn,
              socialProfiles.medium,
              socialProfiles.twitter,
              socialProfiles.tumblr,
              socialProfiles['about.me'],
            ],
          }),
          getSocialProfileSeo({
            name: 'Christopher Tran',
            socialUrls: [socialProfiles.twitter],
          }),
          getBreadCrumbListSeo({
            sites: [{ url: siteUrl + '/', name: siteName }, ...breadCrumb],
          }),
        ]
        return (
          <Helmet
            title={siteTitle}
            titleTemplate={`${siteName} | %s`}
            meta={[
              { name: 'description', content: siteDescription },
              { name: 'image', content: siteLogo },
              { name: 'keywords', content: keywords },
              { name: 'twitter:title', content: siteTitle },
              { name: 'twitter:description', content: siteDescription },
              { name: 'twitter:card', content: 'summary_large_image' },
              { property: 'og:url', content: siteUrl },
              { property: 'og:site_name', content: siteName },
              { property: 'og:title', content: siteTitle },
              { property: 'og:description', content: siteDescription },
              { property: 'og:locale', content: 'en_US' },
              { property: 'og:type', content: 'website' },
            ]}
            script={[
              {
                type: 'application/ld+json',
                innerHTML: JSON.stringify(structuredData),
              },
              ...script,
            ]}
            link={[{ rel: 'canonical', href: siteUrl }]}
          />
        )
      }}
    />
  )
}

export function ArticleMeta({
  articleBody = '',
  author = '',
  date = '',
  datePublished = date,
  dateModified = '',
  description = '',
  excerpt = '',
  genre = '',
  path = '',
  tags = [],
  title = '',
  thumbnail = '',
  wordCount = 0,
  scripts,
}: {
  articleBody?: string
  author?: string
  date?: string
  datePublished?: string
  dateModified?: string
  description?: string
  excerpt?: string
  genre?: string
  path?: string
  tags?: string[]
  title?: string
  thumbnail?: string
  wordCount?: number
  scripts?: Script[]
}) {
  const url = baseUrl + path

  const structuredData = [
    getArticleSeo({
      alternativeHeadline: description || excerpt,
      articleBody,
      author,
      datePublished,
      dateCreated: datePublished,
      dateModified,
      description,
      editor: author,
      genre: genre || 'javascript',
      headline: title,
      image: thumbnail,
      keywords: tags,
      url,
      wordcount: wordCount,
    }),
    getSocialProfileSeo({
      name: 'jsmanifest',
      url,
      socialUrls: [
        socialProfiles.devto,
        socialProfiles.disqus,
        socialProfiles.linkedIn,
        socialProfiles.medium,
        socialProfiles.twitter,
        socialProfiles.tumblr,
        socialProfiles['about.me'],
      ],
    }),
    getBreadCrumbListSeo({
      sites: [{ url, name: title }],
    }),
  ]
  return (
    <Helmet
      title={title}
      titleTemplate="%s – JSManifest"
      meta={[
        { name: 'description', content: description },
        { name: 'image', content: thumbnail },
        { name: 'keywords', content: tags },
        { name: 'og:type', content: 'article' },
        { name: 'og:title', content: title },
        { name: 'og:description', content: description },
        { name: 'og:url', content: url },
        { name: 'og:site_name', content: 'jsmanifest' },
        { name: 'og:image', content: thumbnail },
        { name: 'og:image:width', content: '1667' },
        { name: 'og:image:height', content: '2500' },
        { name: 'og:updated_time', content: dateModified },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: description },
        { name: 'twitter:image', content: thumbnail },
        { property: 'article:published_time', content: datePublished },
        { property: 'article:modified_time', content: dateModified },
        { property: 'article:author', content: author },
        { property: 'article:publisher', content: author },
        { property: 'article:section', content: 'health' },
        { property: 'article:tag', content: tags },
      ]}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(structuredData),
        },
        {
          type: 'text/javascript',
          src: 'https://app.getresponse.com/view_webform_v2.js?u=zpBtw&webforms_id=31425302',
        },
        ...(scripts || []),
      ]}
    >
      <link rel="canonical" href={url} />
    </Helmet>
  )
}
