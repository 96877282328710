import React from 'react'
import { css } from '@emotion/react'
import useTheme from '@mui/material/styles/useTheme'
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TwitterShareButton,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TwitterIcon,
} from 'react-share'

export interface FollowBarProps {
  baseUrl: string
  slug: string
  title: string
}

function FollowBar({ baseUrl = '', slug = '', title = '' }: FollowBarProps) {
  const theme = useTheme()
  const iconProps = { size: 35, round: true }
  const url = `${baseUrl}${slug}`

  function FollowButton({ children }: React.PropsWithChildren<any>) {
    return (
      <div
        css={css`
          text-align: center;
          margin-right: 3px;
          cursor: pointer;
          :hover {
            opacity: 0.8;
          }
          ${theme.breakpoints.down('xs')} {
            margin-right: 0px;
          }
        `}
      >
        {children}
      </div>
    )
  }

  return (
    <div
      css={css`
        margin-bottom: 20px;
        > div {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        ${theme.breakpoints.down('xs')} {
          padding: 0px;
          svg {
            width: 30px;
            height: 30px;
          }
        }
      `}
    >
      <h5
        css={css`
          text-align: center;
          font-weight: 700;
          text-transform: uppercase;
          color: #333;
          margin: 10px 0px;
        `}
      >
        Share This Post
      </h5>
      <div>
        <FollowButton>
          <FacebookShareButton url={url} quote={title} hashtag="#javascript">
            <FacebookIcon {...iconProps} />
          </FacebookShareButton>
        </FollowButton>
        <FollowButton>
          <LinkedinShareButton url={url}>
            <LinkedinIcon {...iconProps} />
          </LinkedinShareButton>
        </FollowButton>
        <FollowButton>
          <RedditShareButton url={url} title={title}>
            <RedditIcon {...iconProps} />
          </RedditShareButton>
        </FollowButton>
        <FollowButton>
          <TwitterShareButton
            title={title}
            via="jsmanifest"
            hashtags={['javascript']}
            url={url}
          >
            <TwitterIcon {...iconProps} />
          </TwitterShareButton>
        </FollowButton>
      </div>
    </div>
  )
}

export default FollowBar
